export const use = [
    'Basket',
    'Charcoal',
    'Charcoal tube',
    'Chopping board',
    'Chopsticks',
    'Composite flooring',
    'Composite',
    'Composite furniture',
    'Concrete reinforcement',
    'Construction',
    'Curtain',
    'Cutlery',
    'Drinking straw',
    'Erosion control',
    'Fencing, live',
    'Fencing material',
    'Fibre(Natural fiber)',
    'Fibre(Viscose / Rayon)',
    'Flattened bamboo flooring',
    'Flooring',
    'Fodder',
    'Furniture',
    'Handicraft',
    'Incense sticks',
    'Indoor flooring',
    'Laminated bamboo',
    'Lumber',
    'Mat',
    'Musical instruments',
    'Oriented strand Board',
    'Ornamental',
    'Outdoor flooring',
    'Pallet',
    'Panel',
    'Panel based furniture',
    'Paper pulp',
    'Particle board',
    'Pellet',
    'Ply bamboo',
    'Pole based furniture',
    'Poles for construction',
    'Rounds sticks',
    'Refinery',
    'Scrimber',
    'Shoot',
    'Shuttering formwork board',
    'Small sticks',
    'Sticks',
    'Strand woven',
    'Strip',
    'Syngas',
    'Textile',
    'Toothpicks',
    'Veneer panels',
    'Vinegar',
    'Wastewater treatment(rootzone treatment)',
    'Weaving'
];