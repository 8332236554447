import React from 'react'
import './custom.css'
import { CheckAuth } from './components/CheckAuth'
import { AdminMenu } from './components/AdminMenu'
import { Footer } from './components/Footer'
import Box, {
    Item
} from 'devextreme-react/box'


export const Manage = () => {

    return (
        <React.Fragment>
            <CheckAuth />
            <AdminMenu />
            <Box>
                <Item
                    ratio={2}
                    baseSize={0}>
                    <Box
                        direction="row"
                        width="100%"
                        height={630}>
                        <Item ratio={1}>

                        </Item>
                        <Item ratio={1}>

                        </Item>
                        <Item ratio={1}>

                        </Item>
                    </Box>
                </Item>
            </Box>


            <Footer />
        </React.Fragment>
    );
}
export default Manage;