import React, { Component } from 'react';

import Box, {
    Item
} from 'devextreme-react/box';

import DocumentTitle from 'react-document-title'

import './DetailItem.css';

export class DetailItem extends React.Component {

    static displayName = DetailItem.name;
    constructor(props) {
        super(props);
        this.state = {
            bamboo: [],
            title: '',
            height: 1850,
            loading: true
        };
    }

    componentDidMount() {
        this.populateBambooData();
        window.addEventListener('resize', this.handleHeight);

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleHeight);
    }

    handleHeight = () => {
        const screenHeight = document.documentElement.clientHeight;
        let height = `${screenHeight - 300}px`;
        this.setState({
            height,
        });
    }

    static renderBambooTable(data) {
        var synonyms = "";
        if (data.Synonyms != '') {
            synonyms = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">Synonyms</div>
                <div className="dx-field-value-static" style={{ whiteSpace: "pre-wrap" }} > {data.Synonyms.replaceAll(";  ", "\n").replaceAll("; ", "\n").replaceAll(";", "\n")}
                </div>
            </div>
        }

        var RhizomeType = "";
        if (data.RhizomeType != '') {
            RhizomeType = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">Rhizome type</div>
                <div className="dx-field-value-static">{data.RhizomeType}</div>
            </div>
        }

        var InCultivation = "";
        if (data.InCultivation != '') {
            InCultivation = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">In cultivation/naturalised in </div>
                <div className="dx-field-value-static" style={{ whiteSpace: "pre-wrap" }}>{data.InCultivation.replaceAll("; ", "\n").replaceAll(";", "\n")}</div>
            </div>
        }

        var NativeHabitat = "";
        if (data.NativeHabitat != '') {
            NativeHabitat = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">Native Habitat</div>
                <div className="dx-field-value-static" style={{ whiteSpace: "pre-wrap" }}>{data.NativeHabitat.replaceAll("; ", "\n").replaceAll(";", "\n")}</div>
            </div>
        }

        var AssociatedVegetation = "";
        if (data.AssociatedVegetation != '') {
            AssociatedVegetation = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">Associated vegetation</div>
                <div className="dx-field-value-static">{data.AssociatedVegetation}</div>
            </div>
        }

        var productInfo = "";
        if (data.AnnualCProduction != '' || data.AnnualBiomass != '' || data.AnnualCSequestered != '') {
            productInfo = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">Productivity:</div>
            </div>
        }

        var AnnualCProduction = "";
        if (data.AnnualCProduction != '' ) {
            AnnualCProduction = 
                <div className="dx-field">
                    <div style={{ paddingLeft: '40px' }} className="dx-field-label">Annual culm (Fresh) production /ha.</div>
                    <div className="dx-field-value-static">{data.AnnualCProduction} </div>
                </div>

        }
        var AnnualBiomass = "";
        if (data.AnnualBiomass != '') {
            AnnualBiomass =
                <div className="dx-field">
                    <div style={{ paddingLeft: '40px' }} className="dx-field-label">Annual Above Ground biomass /ha.</div>
                    <div className="dx-field-value-static">{data.AnnualBiomass} </div>
                </div>

        }
        var AnnualCSequestered = "";
        if (data.AnnualCSequestered != '') {
            AnnualCProduction =
                <div className="dx-field">
                    <div style={{ paddingLeft: '40px' }} className="dx-field-label">Annual C sequestered/ha.</div>
                    <div className="dx-field-value-static">{data.AnnualCSequestered} </div>
                </div>

        }

        var ClimaticParameter = "";
        if (data.TemperatureMin != '0' || data.TemperatureMax != '0'
            || data.PrecipitationMin != '0' || data.PrecipitationMax != '0'
            || data.AltitudeMin != '0' || data.AltitudeMax != '0') {
            ClimaticParameter = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">Climatic Parameters:</div>
            </div>
        }

        var Temperature = "";
        if (data.TemperatureMin != '0' || data.TemperatureMax != '0') {
            Temperature = <div className="dx-field">
                <div style={{ paddingLeft: '40px' }} className="dx-field-label">Temperature (Celcius) </div>
                <div className="dx-field-value-static">{data.TemperatureMin} - {data.TemperatureMax}</div>
            </div>
        }
        var Precipitation = "";
        if ( data.PrecipitationMin != '0' || data.PrecipitationMax != '0' ) {
            Precipitation = <div className="dx-field">
                <div style={{ paddingLeft: '40px' }} className="dx-field-label">Precipitation (in mm) </div>
                <div className="dx-field-value-static">{data.PrecipitationMin} - {data.PrecipitationMax}</div>
            </div>
        }
        var Altitude = "";
        if ( data.AltitudeMin != '0' || data.AltitudeMax != '0') {
            Altitude = <div className="dx-field">
                <div style={{ paddingLeft: '40px' }} className="dx-field-label">Altitude (in m) </div>
                <div className="dx-field-value-static">{data.AltitudeMin} - {data.AltitudeMax}</div>
            </div>
        }
                        
        var SoilParameters = "";
        if (data.SoilType != '' || data.SoilTexture != '' || data.SoilOrganicCarbon != ''
            || data.Drainage != '' || data.SoilDepthMin != '0' || data.SoilDepthMax != '0' || data.pHMin != '0' || data.pHMax != '0' ) {
            SoilParameters =
                <div className="dx-field">
                    <div style={{ paddingLeft: '20px' }} className="dx-field-label">Soil parameters:</div>
                </div>
        }

        var SoilType = "";
        if (data.SoilType != '' || data.SoilTexture != ''  ) {
            SoilType =
                <div className="dx-field">
                    <div style={{ paddingLeft: '40px' }} className="dx-field-label">Soil type / texture </div>
                    <div className="dx-field-value-static">{data.SoilType} {data.SoilTexture}</div>
                </div>
        }

        var SoilOrganicCarbon = "";
        if ( data.SoilOrganicCarbon != '' ) {
            SoilOrganicCarbon =
                <div className="dx-field">
                    <div style={{ paddingLeft: '40px' }} className="dx-field-label">Soil Organic Carbon </div>
                    <div className="dx-field-value-static">{data.SoilOrganicCarbon}</div>
                </div>
        }

        var Drainage = "";
        if (data.Drainage != '' ) {
            Drainage =
                <div className="dx-field">
                    <div style={{ paddingLeft: '40px' }} className="dx-field-label">Drainage </div>
                    <div className="dx-field-value-static">{data.Drainage}</div>
                </div>
        }

        var SoilDepth = "";
        if ( data.SoilDepthMin != '0' || data.SoilDepthMax != '0'  ) {
            SoilDepth =
                <div className="dx-field">
                    <div style={{ paddingLeft: '40px' }} className="dx-field-label">Soil depth (in m) </div>
                    <div className="dx-field-value-static">{data.SoilDepthMin} - {data.SoilDepthMax}</div>
                </div>
        }

        var SoilpH = "";
        if ( data.pHMin != '0' || data.pHMax != '0') {
            SoilpH =
                <div className="dx-field">
                    <div style={{ paddingLeft: '40px' }} className="dx-field-label">Soil pH </div>
                    <div className="dx-field-value-static">{data.pHMin} - {data.pHMax}</div>
                </div>
        }
        return (

            <><DocumentTitle title={data.SpeciesName + " - Inbar species selection"}></DocumentTitle>
                <div key={data.gid} className="dx-fieldset" >
                    <div className="dx-fieldset-header">{data.SpeciesName}</div>
                    <div className="dx-field">
                        <div style={{ paddingLeft: '20px' }} className="dx-field-label">Common names</div>
                        <div className="dx-field-value-static" style={{ whiteSpace: "pre-wrap" }}>
                            {data.CommonNames.replaceAll("; ", "\n").replaceAll(";", "\n")}
                        </div>
                    </div>
                    <div className="dx-field">
                        <div style={{ paddingLeft: '20px' }} className="dx-field-label">Native range</div>
                        <div className="dx-field-value-static" style={{ whiteSpace: "pre-wrap" }} >
                            {data.NativeRange.replaceAll("; ", "\n").replaceAll(";", "\n")}
                        </div>
                    </div>
                    {synonyms}
                    {RhizomeType}
                    {InCultivation}
                    {NativeHabitat}
                    {AssociatedVegetation}
                    {productInfo}
                    {AnnualCProduction}
                    {AnnualBiomass}
                    {AnnualCSequestered}
                    {ClimaticParameter}
                    {Temperature}
                    {Precipitation}
                    {Altitude}
                    {SoilParameters}
                    {SoilType}
                    {SoilOrganicCarbon}
                    {Drainage}
                    {SoilDepth}
                    {SoilpH}
                    
                    
                    <div className="dx-field">
                        <div style={{ paddingLeft: '20px' }} className="dx-field-label">Uses</div>
                        <div className="dx-field-value-static" style={{ whiteSpace: "pre-wrap" }}>{data.Uses.replaceAll(";  ", "\n").replaceAll("; ", "\n").replaceAll(";", "\n")} </div>
                    </div>
                </div></>


        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : DetailItem.renderBambooTable(this.state.bamboo);

        return (
            <Box style={{ marginLeft: '50px' }}
                direction="row"
                width="80%"
                height={'auto'}
                align="start"
                crossAlign="start">
                <Item ratio={0} baseSize={1024}>
                    {contents}
                </Item>
            </Box>
        );
    }

    async populateBambooData() {
        var url = window.location.href;
        var arr = url.split('/')
        var guid = arr[arr.length - 1]
        const response = await fetch('api/BambooData/' + guid);
        const data = await response.json();
        this.setState({ bamboo: data, loading: false });
    }
}