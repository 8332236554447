import React, { useState } from "react"

import { useIsAuthenticated } from 'react-auth-kit'
import { Redirect } from 'react-router-dom'

export const CheckAuth = () => {
    const isAuthenticated = useIsAuthenticated()

    if (isAuthenticated()) {
        return <Redirect to='/manage' />
    }
    else {
        return <Redirect to='/auth' />
    }
}