export const bambooregion = [
    'Anadamans',
    'Angola',
    'Argentina',
    'Australia',
    'Bangladesh',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Brazil',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Cayman Islands',
    'Central African Republic',
    'Chad',
    'China',
    'Colombia',
    'Congo',
    'Costa Rica',
    'Cuba',
    'Dominican Republic',
    'Ecuador',
    'El Salvador',
    'Eritrea',
    'Ethiopia',
    'Europe',
    'French Guiana',
    'Gabon',
    'Ghana',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'India',
    'Indonesia',
    'Iraq',
    'Jamaica',
    'Japan',
    'Java',
    'Kenya',
    'Korea',
    'Laos',
    'Liberia',
    'Libya',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Mauritius',
    'Mexico',
    'Mozambique',
    'Myanmar',
    'Nepal',
    'New Guinea',
    'New Zealand',
    'Nicaragua',
    'Nigeria',
    'Pakistan',
    'Panama',
    'Papua New Guinea',
    'Peru',
    'Philippines',
    'Puerto Rico',
    'Rwanda',
    'Senegal',
    'Sierra Leone',
    'Singapore',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Sumatera',
    'Suriname',
    'Tanzania',
    'Thailand',
    'Togo',
    'U.S.A.',
    'Uganda',
    'Uraguay',
    'Venezuela',
    'Vietnam',
    'Wallis and Futuna',
    'Zaire',
    'Zambia',
    'Zimbabwe'

];