import React from 'react';
import './custom.css';
import { TopHeader } from './components/TopHeader';
import { Footer } from './components/Footer';
import SelectBox from 'devextreme-react/select-box';
import { RangeSlider, Tooltip } from 'devextreme-react/range-slider';
import TagBox from 'devextreme-react/tag-box';
import DataGrid, { Export, Pager, Paging, Column } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';

import { use } from './components/usedata.js';
import { soiltype } from './components/soiltype.js';
import { drainage } from './components/drainage.js';
import { soiltexture } from './components/soiltexture.js';
import { bambooregion } from './components/bambooregion.js';
import {
    Link
} from "react-router-dom";

import Box, {
    Item
} from 'devextreme-react/box';

import 'whatwg-fetch';
import CustomStore from 'devextreme/data/custom_store';

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

const store = new CustomStore({
    key: 'Id',
    load: function (loadOptions) {
        let params = '?';
        [
            'skip',
            'take',
            'requireTotalCount',
            'requireGroupCount',
            'sort',
            'filter',
            'totalSummary'
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
        });
        params = params.slice(0, -1);
        //var url = window.location.href + 'api/';
        return fetch(`api/BambooData${params}`)
            .then(response => response.json())
            .then((data) => {
                return {
                    data: data.data,
                    totalCount: data.totalCount,
                    summary: data.summary
                };
            })
            .catch(() => { throw 'Data Loading Error'; });
    }
});

class Bamboo extends React.Component {
    constructor(props) {
        super(props)
        this.dataGridRef = React.createRef();
        this.state = {
            TemperatureMin: -30,
            TemperatureMax: 40,
            PrecipitationMin: 0,
            PrecipitationMax: 5000,
            AltitudeMin: 0,
            AltitudeMax: 5000,
            SoilType: '',
            SoilTexture: '',
            Geolocation: '',
            SoilOrganicCarbon: '',
            Drainage: '',
            SoilDepthMin: 0,
            SoilDepthMax: 100,
            Use: ''
        }

        this.onUseValueChanged = this.onUseValueChanged.bind(this);
        this.onTempRangeChanged = this.onTempRangeChanged.bind(this);
        this.onPrecipRangeChanged = this.onPrecipRangeChanged.bind(this);
        this.onAltitRangeChanged = this.onAltitRangeChanged.bind(this);
        this.onSoilDepthRangeChanged = this.onSoilDepthRangeChanged.bind(this);
        this.onPHChanged = this.onPHChanged.bind(this);
        this.onSoilTypeChanged = this.onSoilTypeChanged.bind(this);
        this.onTextureChanged = this.onTextureChanged.bind(this);
        this.onOrganicChanged = this.onOrganicChanged.bind(this);
        this.onDrainageChanged = this.onDrainageChanged.bind(this);
        this.doFilter = this.doFilter.bind(this);
        this.onGeolocationChanged = this.onGeolocationChanged.bind(this);
    }



    render() {
        return (
            <React.Fragment>
                <TopHeader />
                <Box>
                    <Item
                        ratio={2}
                        baseSize={0}>
                        <Box
                            direction="row"
                            width="100%"
                            height={430}>
                            <Item ratio={1}>
                                <div className="dx-fieldset">
                                    <div className="dx-field">
                                        <div className="dx-fieldset-header">Climatic Parameters</div>
                                        <div className="dx-field-label">Temperature:</div>
                                        <div className="dx-field-value">
                                            <RangeSlider min={-30} max={40} defaultValue={[-30, 40]} tooltip={{
                                                enabled: true,
                                                format: format,
                                                showMode: 'always',
                                                position: 'bottom'
                                            }} start={this.state.TemperatureMin} end={this.state.TemperatureMax} onValueChanged={this.onTempRangeChanged} />
                                        </div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">Precipitation:</div>
                                        <div className="dx-field-value">
                                            <RangeSlider min={0} max={5000} defaultValue={[0, 5000]} tooltip={{
                                                enabled: true,
                                                format: mmformat,
                                                showMode: 'always',
                                                position: 'bottom'
                                            }} start={this.state.PrecipitationMin} end={this.state.PrecipitationMax} onValueChanged={this.onPrecipRangeChanged} />
                                        </div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">Altitude:</div>
                                        <div className="dx-field-value">
                                            <RangeSlider min={0} max={5000} defaultValue={[0, 5000]} tooltip={{
                                                enabled: true,
                                                format: formatm,
                                                showMode: 'always',
                                                position: 'bottom'
                                            }} start={this.state.AltitudeMin} end={this.state.AltitudeMax} onValueChanged={this.onAltitRangeChanged} />
                                        </div>
                                    </div>
                                </div>
                            </Item>
                            <Item ratio={1}>
                                <div className="dx-fieldset">
                                    <div className="dx-fieldset-header">Soil parameters</div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">Soil texture:</div>
                                        <SelectBox items={soiltexture}
                                            searchEnabled={true}  className="dx-field-value" defaultValue="" showClearButton={true} onValueChanged={this.onTextureChanged} />
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">Drainage:</div>
                                        <SelectBox items={drainage} className="dx-field-value" defaultValue="" showClearButton={true} onValueChanged={this.onDrainageChanged} />
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">Soil pH:</div>
                                        <div className="dx-field-value">
                                            <RangeSlider min={1} max={14} defaultValue={[1, 14]} tooltip={{
                                                enabled: true,
                                                showMode: 'always',
                                                position: 'bottom'
                                            }} start={this.state.SoilPHMin} end={this.state.SoilPHMax} onValueChanged={this.onPHChanged} />
                                        </div>
                                    </div>
                                </div>
                            </Item>
                            <Item ratio={1}>
                                <div className="dx-fieldset">
                                    <div className="dx-fieldset-header">Uses parameters</div>
                                    <div className="dx-field">

                                        <TagBox items={use}
                                            searchEnabled={true} onValueChanged={this.onUseValueChanged} />

                                    </div>
                                </div>
                                <div className="dx-fieldset" style={{ margin: "2px 20px 30px 20px" }}>
                                    <div className="dx-fieldset-header">Geographical location</div>
                                    <div className="dx-field">
                                        <SelectBox style={{ width: "100%" }} searchEnabled={true} items={bambooregion} className="dx-field-value" defaultValue="" showClearButton={true} onValueChanged={this.onGeolocationChanged} />
                                    </div>
                                </div>
                                <div className="dx-fieldset" >
                                    <Button
                                        width={120}
                                        text="Search"
                                        type="default"
                                        stylingMode="contained"
                                        onClick={this.doFilter}
                                    />
                                </div>
                            </Item>
                        </Box>
                    </Item>
                </Box>
                <Box>
                    <Item
                        ratio={2}
                        baseSize={0}>
                        <Box
                            direction="row"
                            width="100%"
                            height={'auto'}>
                            <Item ratio={1}>
                                <DataGrid
                                    dataSource={store}
                                    remoteOperations={true}
                                    keyExpr="Id"                                   
                                    allowColumnResizing={true}
                                    columnResizingMode='nextColumn'
                                    columnMinWidth={50}
                                    columnAutoWidth={true}
                                    showBorders={true}
                                    selection={{ mode: 'single' }}
                                    hoverStateEnabled={true}
                                    rowAlternationEnabled={true}
                                    onSelectionChanged={this.onSelectionChanged}
                                    ref={ref => this.dataGridRef = ref} >
                                    {/*<Column dataField="Id"*/}
                                    {/*    width={100}*/}
                                    {/*    allowSorting={false}*/}
                                    {/*    cellRender={cellRender}*/}
                                    {/*/>*/}
                                    <Column allowSorting={false} width={300} dataField="SpeciesName" caption="Species Name" cellRender={cellRender} />
                                    <Column allowSorting={false} dataField="RhizomeType" caption="Rhizome type" />
                                    <Column allowSorting={false} dataField="NativeRange" caption="Native range" />
                                    <Column allowSorting={false} dataField="InCultivation" caption="In cultivation/naturalised in" />
                                    <Column allowSorting={false} dataField="Uses" caption="Uses" />
                                    <Paging defaultPageSize={10} />
                                    <Pager
                                        visible={true}
                                        allowedPageSizes={true}
                                        displayMode={'full'}
                                        showPageSizeSelector={false}
                                        showInfo={true}
                                        showNavigationButtons={true} />
                                    <Export enabled={true} allowExportSelectedData={false} />
                                </DataGrid>
                            </Item>

                        </Box>
                    </Item>
                </Box>

                <Footer />
            </React.Fragment>
        );
    }

    doFilter() {
        const dataGrid = this.dataGridRef.instance;
        //dataGrid.filter(['TemperatureMin', '=', this.state.TemperatureMin],
        //    ['TemperatureMax', '=', this.state.TemperatureMax],
        //    ['PrecipitationMin', '=', this.state.PrecipitationMin],
        //    ['PrecipitationMax', '=', this.state.PrecipitationMax],
        //    ['AltitudeMin', '=', this.state.AltitudeMin],
        //    ['AltitudeMax', '=', this.state.AltitudeMax],
        //    ['SoilType', '=', this.state.SoilType],
        //    ['SoilTexture', '=', this.state.SoilTexture],
        //    ['SoilOrganicCarbon', '=', this.state.SoilOrganicCarbon],
        //    ['Drainage', '=', this.state.Drainage],
        //    ['SoilDepthMin', '=', this.state.SoilDepthMin],
        //    ['SoilDepthMax', '=', this.state.SoilDepthMax],
        //    ['Use', '=', this.state.Use],
        //);
        dataGrid.filter({
            "TemperatureMin": this.state.TemperatureMin,
            "TemperatureMax": this.state.TemperatureMax,
            "PrecipitationMin": this.state.PrecipitationMin,
            "PrecipitationMax": this.state.PrecipitationMax,
            "AltitudeMin": this.state.AltitudeMin,
            "AltitudeMax": this.state.AltitudeMax,
            "SoilType": this.state.SoilType,
            "SoilTexture": this.state.SoilTexture,
            "SoilOrganicCarbon": this.state.SoilOrganicCarbon,
            "Drainage": this.state.Drainage,
            "SoilDepthMin": this.state.SoilDepthMin,
            "SoilDepthMax": this.state.SoilDepthMax,
            "SoilPHMin": this.state.SoilPHMin,
            "SoilPHMax": this.state.SoilPHMax,
            "Use": this.state.Use,
            "Geolocation": this.state.Geolocation
        });

    }
    onSoilTypeChanged({ value }) {
        this.setState({
            SoilType: value
        });
    }
    onTextureChanged({ value }) {
        this.setState({
            SoilTexture: value
        });
    }
    onOrganicChanged({ value }) {
        this.setState({
            SoilOrganicCarbon: value
        });
    }
    onDrainageChanged({ value }) {
        this.setState({
            Drainage: value
        });
    }
    onGeolocationChanged({ value }) {
        this.setState({
            Geolocation: value
        });
    }
    onUseValueChanged({ value }) {
        this.setState({
            Use: value
        });
    }

    onTempRangeChanged(data) {
        this.setState({
            TemperatureMin: data.start,
            TemperatureMax: data.end
        });
    }

    onPrecipRangeChanged(data) {
        this.setState({
            PrecipitationMin: data.start,
            PrecipitationMax: data.end
        });
    }

    onAltitRangeChanged(data) {
        this.setState({
            AltitudeMin: data.start,
            AltitudeMax: data.end
        });
    }

    onSoilDepthRangeChanged(data) {
        this.setState({
            SoilDepthMin: data.start,
            SoilDepthMax: data.end
        });
    }

    onPHChanged(data) {
        this.setState({
            SoilPHMin: data.start,
            SoilPHMax: data.end
        });
    }

    onExporting(e) {
        //const workbook = new ExcelJS.Workbook();
        //const worksheet = workbook.addWorksheet('Main sheet');

        //exportDataGrid({
        //    component: e.component,
        //    worksheet: worksheet,
        //    autoFilterEnabled: true
        //}).then(() => {
        //    workbook.xlsx.writeBuffer().then((buffer) => {
        //        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
        //    });
        //});
        e.cancel = true;
    }
}

function cellRender(data) {
    return <Link target="_blank" to={"/bamboo/detail/" + data.data['gid']} > {data.value} </Link>
}

function format(value) {
    return `${value}℃`;
}

function mmformat(value) {
    return `${value}mm`;
}

function cmformat(value) {
    return `${value}cm`;
}

function formatm(value) {
    return `${value}m`;
}

export default Bamboo;