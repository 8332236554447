import React from 'react'
import { useSignOut } from 'react-auth-kit'
import './TopHeader.css';
import Box, {
    Item
} from 'devextreme-react/box';

export const AdminMenu = () => {
    const signOut = useSignOut()


    return (
        <><Box className="header-sticky--green " direction="row" width="100%" height={94}>
            <Item baseSize="200px">
                <a href="https://www.inbar.int">
                    <img className="header-sticky__logo" src="images/INBAR-logo_simple-1.png" alt="" />
                </a>
            </Item>
            <Item baseSize="950px" align="center">
                <h1 className="rst-tagline">International Bamboo and Rattan Organisation</h1>
            </Item>
            <Item baseSize="50px">

            </Item>
        </Box>

            <Box className="rst-header-menu " direction="row" width="100%" height={49}>
                <Item baseSize="800px">
                    <ul id="menu-header-menu-v2" className="header-sticky__menu">
                        <li id="menu-item-113630" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-113630">
                            <a href="/manage/bamboo">Manage Bamboo</a></li>
                        <li id="menu-item-10870" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-10870">
                            <a href="/manage/rattan">Manage Rattan</a>

                        </li>

                        <li id="menu-item-10870" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-10870">
                            <button onClick={() => signOut()}>Sign Out</button>
                        </li>
                    </ul>
                </Item>
            </Box></>

    );

}
