import React from 'react';
import './custom.css';
import { AdminMenu } from './components/AdminMenu';
import { Footer } from './components/Footer';
import { DetailItem } from './components/DetailItem';

export class BambooManageDetail extends React.Component {
    render() {
        return (
            <React.Fragment>
                <AdminMenu />

                <DetailItem />

                <Footer />
            </React.Fragment>
        );
    }


}

export default BambooManageDetail;