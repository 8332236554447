import React from 'react';
import './custom.css';
import { TopHeader } from './components/TopHeader';
import { Footer } from './components/Footer';
import TextBox from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';
import Box, {
    Item
} from 'devextreme-react/box';

import 'whatwg-fetch';


class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userName: "",
            password: "",
            email: ""
        }
        this.onUserNameChanged = this.onUserNameChanged.bind(this);
        this.onEmailChanged = this.onEmailChanged.bind(this);
        this.onPasswordChanged = this.onPasswordChanged.bind(this);
        this.doRegister = this.doRegister.bind(this);
    }



    render() {
        return (
            <React.Fragment>
                <TopHeader />
                <Box>
                    <Item
                        ratio={2}
                        baseSize={0}>
                        <Box
                            direction="row"
                            width="100%"
                            height={430}>
                            <Item ratio={1}>
                            </Item>
                            <Item ratio={1}>
                                <div className="dx-fieldset">
                                    <div className="dx-field">
                                        <div className="dx-field-label">Name:</div>
                                        <div className="dx-field-value">
                                            <TextBox defaultValue="" placeholder="Enter name" showClearButton={true}
                                                onValueChanged={this.onUserNameChanged} />
                                        </div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">Email:</div>
                                        <div className="dx-field-value">
                                            <TextBox defaultValue="" placeholder="Enter email" showClearButton={true}
                                                onValueChanged={this.onEmailChanged} />
                                        </div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">Password:</div>
                                        <div className="dx-field-value">
                                            <TextBox mode="password" placeholder="Enter password" showClearButton={true} defaultValue=""
                                                onValueChanged={this.onPasswordChanged} />
                                        </div>
                                    </div>

                                    <div className="dx-field">
                                        <div className="dx-field-label"></div>
                                        <Button
                                            width={120}
                                            text="Register"
                                            type="default"
                                            stylingMode="contained"
                                            onClick={this.doRegister}
                                        />
                                    </div>
                                </div>

                            </Item>
                            <Item ratio={1}>
                                
                            </Item>
                        </Box>
                    </Item>
                </Box>
                

                <Footer />
            </React.Fragment>
        );
    }

    onUserNameChanged( value ) {
        this.setState({
            userName: value
        });
    }

    onEmailChanged(value) {
        this.setState({
            email: value
        });
    }

    onPasswordChanged(value) {
        this.setState({
            password: value
        });
    }

    async doRegister() {
        var u = this.state.userName.value;
        var p = this.state.password.value;
        var e = this.state.email.value;

        const response = await fetch('api/Auth/register', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Name: u, Password: p, Email:e })
        });
        const data = await response.json();
        this.props.history.push('/auth');
    }

}

export default App;