import React, { useState } from "react";
import { useSignIn } from 'react-auth-kit'
import { Redirect } from 'react-router-dom'
import './custom.css';
import { TopHeader } from './components/TopHeader';
import { Footer } from './components/Footer';
import TextBox from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';
import Box, {
    Item
} from 'devextreme-react/box';
import 'whatwg-fetch';

export const Auth = () => {
    const signIn = useSignIn()
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [redirect, setRedirect] = useState(false)

    const doLogin = async (e) => {

        const response = await fetch('api/Auth/login', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Email: userName, Password: password })
        });
        const res = await response.json();
        if (signIn({
            token: res.data.token,
            expiresIn: res.data.expiresIn,
            tokenType: "Bearer",
            authState: res.data.authState,
            refreshToken: res.data.refreshToken,                    // Only if you are using refreshToken feature
            refreshTokenExpireIn: res.data.refreshTokenExpireIn
        })) { // Only if you are using refreshToken feature
            // Redirect or do-something
        } else {
            //Throw error
        }
        setRedirect(true)
    }

    if (redirect) {
        return <Redirect to='/manage' />
    }


    return (
        <React.Fragment>
            <TopHeader />
            <Box>
                <Item
                    ratio={2}
                    baseSize={0}>
                    <Box
                        direction="row"
                        width="100%"
                        height={430}>
                        <Item ratio={1}>
                        </Item>
                        <Item ratio={1}>
                            <div className="dx-fieldset">
                                <div className="dx-field">
                                    <div className="dx-field-label">Email:</div>
                                    <div className="dx-field-value">
                                        <TextBox defaultValue="" placeholder="Enter email" showClearButton={true}
                                            onValueChanged={e => setUserName(e.value)} />
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label">Password:</div>
                                    <div className="dx-field-value">
                                        <TextBox mode="password" placeholder="Enter password" showClearButton={true} defaultValue=""
                                            onValueChanged={e => setPassword(e.value)} />
                                    </div>
                                </div>

                                <div className="dx-field">
                                    <div className="dx-field-label"></div>
                                    <Button
                                        width={120}
                                        text="Login"
                                        type="default"
                                        stylingMode="contained"
                                        onClick={doLogin }
                                    />
                                </div>
                            </div>

                        </Item>
                        <Item ratio={1}>

                        </Item>
                    </Box>
                </Item>
            </Box>


            <Footer />
        </React.Fragment>
    )
}
