import React, { Component } from 'react';
import './Footer.css';
import './font.css';
import Box, {
    Item
} from 'devextreme-react/box';


export class Footer extends Component {
    static displayName = Footer.name;

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <Box>
                <Item ratio={1}>
                    <footer className="footer footer--v2">
                        <div>                 
                            <div className="footer__social">
                                <ul>
                                    <li>
                                        <a href="https://twitter.com/INBARofficial" target="_blank">
                                            <i className="fa fa-twitter"></i>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="https://www.facebook.com/INBARofficial/" target="_blank">
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="https://www.linkedin.com/company/international-bamboo-and-rattan-organisation" target="_blank">
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="http://weibo.com/p/1006062044486527/home" target="_blank">
                                            <i className="fa fa-weibo"></i>
                                        </a>
                                    </li>



                                    <li>
                                        <a href="https://www.youtube.com/user/INBARChannel" target="_blank">
                                            <i className="fa fa-youtube"></i>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="https://www.flickr.com/photos/71018429@N07/" target="_blank">
                                            <i className="fa fa-flickr"></i>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="https://instagram.com/inbarofficial" target="_blank">
                                            <i className="fa fa-instagram"></i>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                            <div className="footer__logo">
                                <a href="https://www.inbar.int" >
                                    <img className="header-sticky__logo" src="images/INBAR-logo_simple-1.png" alt="" />
                                </a>
                            </div>
                        </div>
                    </footer>
                </Item>
            </Box>
        );
    }
}
