import React from 'react';
import './custom.css';
import { AdminMenu } from './components/AdminMenu';
import { Footer } from './components/Footer';
import TextBox from 'devextreme-react/text-box';
import TagBox from 'devextreme-react/tag-box';
import DataGrid, { Export, Pager, Paging, Column } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { use } from './components/usedata.js';
import {
    Link
} from "react-router-dom";

import Box, {
    Item
} from 'devextreme-react/box';

import 'whatwg-fetch';

import CustomStore from 'devextreme/data/custom_store';

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

const store = new CustomStore({
    key: 'Id',
    load: function (loadOptions) {
        let params = '?';
        [
            'skip',
            'take',
            'requireTotalCount',
            'requireGroupCount',
            'sort',
            'filter',
            'totalSummary'
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
        });
        params = params.slice(0, -1);
        return fetch(`https://speciestool.inbar.int/api/RattanData${params}`)
            .then(response => response.json())
            .then((data) => {
                return {
                    data: data.data,
                    totalCount: data.totalCount,
                    summary: data.summary
                };
            })
            .catch(() => { throw 'Data Loading Error'; });
    }
});

class App extends React.Component {
    constructor(props) {
        super(props)
        this.dataGridRef = React.createRef();
        this.state = {
            TemperatureMin: 20,
            TemperatureMax: 25,
            PrecipitationMin: 200,
            PrecipitationMax: 3000,
            AltitudeMin: 0,
            AltitudeMax: 3000,
            SoilType: '',
            SoilTexture: '',
            SoilOrganicCarbon: '',
            Drainage: '',
            SoilDepthMin: 0,
            SoilDepthMax: 100,
            Use: ''
        }

        this.onUseValueChanged = this.onUseValueChanged.bind(this);
        this.onTempRangeChanged = this.onTempRangeChanged.bind(this);
        this.onPrecipRangeChanged = this.onPrecipRangeChanged.bind(this);
        this.onAltitRangeChanged = this.onAltitRangeChanged.bind(this);
        this.onSoilDepthRangeChanged = this.onSoilDepthRangeChanged.bind(this);
        this.onPHChanged = this.onPHChanged.bind(this);
        this.doFilter = this.doFilter.bind(this);
    }



    render() {
        return (
            <React.Fragment>
                <AdminMenu />
                <Box>
                    <Item
                        ratio={2}
                        baseSize={0}>
                        <Box
                            direction="row"
                            width="100%"
                            height={430}>
                            <Item ratio={1}>
                                <div className="dx-fieldset">
                                    <div className="dx-field">
                                        <div className="dx-field-label">Scientific Name:</div>
                                        <TextBox className="dx-field-value" defaultValue="" showClearButton={true} />
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">Vernacular Name:</div>
                                        <TextBox className="dx-field-value" defaultValue="" showClearButton={true} />
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">Geographical:</div>
                                        <TextBox className="dx-field-value" defaultValue="" showClearButton={true} />
                                    </div>
                                </div>
                            </Item>
                            <Item ratio={1}>
                                <div className="dx-fieldset">
                                    <div className="dx-field">
                                        <div className="dx-field-label">Diseases:</div>
                                        <TextBox className="dx-field-value" defaultValue="" showClearButton={true} />
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label">Properties:</div>
                                        <TextBox className="dx-field-value" defaultValue="" showClearButton={true} />
                                    </div>
                                </div>
                            </Item>
                            <Item ratio={1}>
                                <div className="dx-fieldset">
                                    <div className="dx-field">
                                        <div className="dx-field-label">Uses:</div>
                                        <TagBox items={use}
                                            searchEnabled={true} onValueChanged={this.onUseValueChanged} />

                                    </div>
                                    <div className="dx-field">
                                        <Button
                                            width={120}
                                            text="Search"
                                            type="default"
                                            stylingMode="contained"
                                            onClick={this.SearchBamboo}
                                        />
                                    </div>
                                </div>
                            </Item>
                        </Box>
                    </Item>
                </Box>
                <Box>
                    <Item
                        ratio={2}
                        baseSize={0}>
                        <Box
                            direction="row"
                            width="100%"
                            height={'auto'}>
                            <Item ratio={1}>
                                <DataGrid
                                    dataSource={store}
                                    remoteOperations={true}
                                    keyExpr="Id"
                                    allowColumnResizing={true}
                                    columnResizingMode='nextColumn'
                                    columnMinWidth={50}
                                    columnAutoWidth={true}
                                    showBorders={true}
                                    selection={{ mode: 'single' }}
                                    hoverStateEnabled={true}
                                    rowAlternationEnabled={true}
                                    onSelectionChanged={this.onSelectionChanged}
                                    ref={ref => this.dataGridRef = ref} >
                                    {/*<Column dataField="Id"*/}
                                    {/*    width={100}*/}
                                    {/*    allowSorting={false}*/}
                                    {/*    cellRender={cellRender}*/}
                                    {/*/>*/}
                                    <Column allowSorting={false} width={300} dataField="ScientificName" caption="Scientific Name" cellRender={cellRender} />
                                    <Column allowSorting={false} dataField="VernacularNames" caption="Vernacular Names" />
                                    <Column allowSorting={false} dataField="Geographical" caption="Geographical" />
                                    <Column allowSorting={false} dataField="Uses" caption="Uses" />
                                    <Column allowSorting={false} dataField="Silviculture" caption="Silviculture" />
                                    <Column allowSorting={false} dataField="Diseases" caption="Diseases" />
                                    <Paging defaultPageSize={10} />
                                    <Pager
                                        visible={true}
                                        allowedPageSizes={true}
                                        displayMode={'full'}
                                        showPageSizeSelector={false}
                                        showInfo={true}
                                        showNavigationButtons={true} />
                                    <Export enabled={false} allowExportSelectedData={false} />
                                </DataGrid>
                            </Item>

                        </Box>
                    </Item>
                </Box>

                <Footer />
            </React.Fragment>
        );
    }

    doFilter() {
        const dataGrid = this.dataGridRef.instance;
        dataGrid.filter({
            "TemperatureMin": this.state.TemperatureMin,
            "TemperatureMax": this.state.TemperatureMax,
            "AltitudeMin": this.state.AltitudeMin,
            "AltitudeMax": this.state.AltitudeMax,
            "SoilType": this.state.SoilType,
            "SoilTexture": this.state.SoilTexture,
            "SoilOrganicCarbon": this.state.SoilOrganicCarbon,
            "Drainage": this.state.Drainage,
            "SoilDepthMin": this.state.SoilDepthMin,
            "SoilDepthMax": this.state.SoilDepthMax,
            "Use": this.state.Use,
        });

    }
    onUseValueChanged({ value }) {
        this.setState({
            Use: value
        });
        this.doFilter();
    }

    onTempRangeChanged(data) {
        this.setState({
            TemperatureMin: data.start,
            TemperatureMax: data.end
        });
        this.doFilter();
    }

    onPrecipRangeChanged(data) {
        this.setState({
            PrecipitationMin: data.start,
            PrecipitationMax: data.end
        });
        this.doFilter();
    }

    onAltitRangeChanged(data) {
        this.setState({
            AltitudeMin: data.start,
            AltitudeMax: data.end
        });
        this.doFilter();
    }

    onSoilDepthRangeChanged(data) {
        this.setState({
            SoilDepthMin: data.start,
            SoilDepthMax: data.end
        });
        this.doFilter();
    }

    onPHChanged(data) {
        this.setState({
            SoilPHMin: data.start,
            SoilPHMax: data.end
        });
        this.doFilter();
    }

    onExporting(e) {
        //const workbook = new ExcelJS.Workbook();
        //const worksheet = workbook.addWorksheet('Main sheet');

        //exportDataGrid({
        //    component: e.component,
        //    worksheet: worksheet,
        //    autoFilterEnabled: true
        //}).then(() => {
        //    workbook.xlsx.writeBuffer().then((buffer) => {
        //        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
        //    });
        //});
        e.cancel = true;
    }
}

function cellRender(data) {
    return <Link target="_blank" to={"/rattan/detail/" + data.data['gid']} > {data.value} </Link>
}

function format(value) {
    return `${value}℃`;
}

function mmformat(value) {
    return `${value}mm`;
}

function cmformat(value) {
    return `${value}cm`;
}

function formatm(value) {
    return `${value}m`;
}

export default App;