export const soiltexture = [
    'Clay',
    'Sandy Clay',
    'Silty Clay',
    'Clay loam',
    'Sandy Clay loam',
    'Silty clay loam',
    'Sand',
    'Loam Sand',
    'Sand Loam',
    'Loam',
    'Silt Loam',
    'Silt'
];