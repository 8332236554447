import React from 'react';
import './custom.css';
import { AdminMenu } from './components/AdminMenu';
import { Footer } from './components/Footer';
import { RattanDetailItem } from './components/rattanDetailItem';

class Detail extends React.Component {
    render() {
        return (
            <React.Fragment>
                <AdminMenu />

                <RattanDetailItem />

                <Footer />
            </React.Fragment>
        );
    }


}

export default Detail;