import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthProvider, PrivateRoute } from 'react-auth-kit'
import './custom.css';
import Bamboo from './bamboo';
import Rattan from './rattan';
import BambooDetail from './bambooDetail';
import RattanDetail from './rattanDetail';
import Register from './reg';
import BambooManage from './bambooManage';
import BambooManageDetail from './bambooItemManage';
import RattanManage from './rattanManage';
import RattanDetailManage from './rattanDetailManage';

import { Auth } from './Auth';
import { Home } from './Home';
import { About } from './About';
import { Manage } from './Manage';

const App = () => (
    <AuthProvider authType={'cookie'}
        authName={'_auth'}
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "http"}
        refreshToken>
        <Router>
            <Switch>
                <Route exact path="/" component={Home} />
                
                <Route exact path="/about" component={About} />
                <Route exact path="/bamboo" component={Bamboo} />
                <Route exact path="/bamboo/detail/:id" component={BambooDetail} />
                <Route exact path="/rattan" component={Rattan} />
                <Route exact path="/rattan/detail/:id" component={RattanDetail} />
                <Route exact path="/auth" component={Auth} />

                <Route exact path="/register" component={Register} />
                <PrivateRoute exact path="/manage" component={Manage} loginPath={'/auth'} />
                <PrivateRoute exact path="/manage/bamboo" component={BambooManage} loginPath={'/auth'} />
                <PrivateRoute exact path="/manage/bamboo/detail/:id" component={BambooManageDetail} loginPath={'/auth'} />
                <PrivateRoute exact path="/manage/rattan" component={RattanManage} loginPath={'/auth'} />
                <PrivateRoute exact path="/manage/rattan/detail/:id" component={RattanDetailManage} loginPath={'/auth'} />
            </Switch>
        </Router>
    </AuthProvider>
);

export default App;
