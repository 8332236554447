import React from 'react';
import './custom.css';
import { TopHeader } from './components/TopHeader';
import { Footer } from './components/Footer';
import Box, {
    Item
} from 'devextreme-react/box';

export const About = () => {
    return (
            <React.Fragment>
                <TopHeader />
                <Box>
                    <Item
                        ratio={2}
                        baseSize={0}>
                        <Box
                            direction="row"
                            width="100%"
                            height={700}>
                            <Item ratio={1}>
                            </Item>
                            <Item ratio={1}>
                                <div className="dx-fieldset">
                                The data in INBAR's Bamboo and Rattan Species Selection tool is regularly updated, based on existing scientific evidences. Bamboo and Rattan Species Selection tool is designed with aim to help users to select right species for right purpose and right habitat. The tool contains sets of environmental parameters (such as soil, climatic conditions), ecological parameters (such as associated vegetation communities, existing knowledge of propagation and management) and product development parameters, which will be used for searching the most suitable species for development.
                                </div>
                                <div className="dx-fieldset">
                                The user will select species by providing input data consisting of ecological conditions of the site and product expectation. Then, the tool will sort out species that matches site conditions and product expectation. Selected species should be able to grow in the soil and climatic conditions of the site while be able to deliver suitable culms for development of desirable products and supply chains.
                                </div>
                            </Item>
                            <Item ratio={1}>
                                
                            </Item>
                        </Box>
                    </Item>
                </Box>
                

                <Footer />
            </React.Fragment>

    );

};