import React from 'react';
import './custom.css';
import { TopHeader } from './components/TopHeader';
import { Footer } from './components/Footer';
import { RattanDetailItem } from './components/rattanDetailItem';


class Detail extends React.Component {
    render() {
        return (
            <React.Fragment>

                <TopHeader />
                
                <RattanDetailItem />

                <Footer />
            </React.Fragment>
        );
    }


}

export default Detail;