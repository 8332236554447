import React, { Component } from 'react';
import './TopHeader.css';
import Box, {
    Item
} from 'devextreme-react/box';


export class TopHeader extends Component {
    static displayName = TopHeader.name;

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (

            <><Box className="header-sticky--green " direction="row" width="100%" height={94}>
                <Item baseSize="200px">
                    <a href="https://www.inbar.int">
                        <img className="header-sticky__logo" src="images/INBAR-logo_simple-1.png" alt="" />
                    </a>
                </Item>
                <Item baseSize="950px" align="center">
                    <h1 className="rst-tagline"> INBAR Species Selection </h1>
                </Item>
                <Item baseSize="50px">

                </Item>
            </Box>

                <Box className="rst-header-menu " direction="row" width="100%" height={49}>
                    <Item baseSize="800px">
                        <ul id="menu-header-menu-v2" className="header-sticky__menu">
                            <li id="menu-item-113630" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-113630">
                                <a href="/">Home</a></li>
                            <li id="menu-item-113630" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-113630">
                                <a href="/bamboo">Bamboo</a></li>
                            <li id="menu-item-10870" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-10870">
                                <a href="/rattan">Rattan</a>

                            </li>
                        </ul>
                    </Item>
                </Box></>

        );
    }
}
