export const rattanuse = [
    'Basket',
    'Edible fruits',
    'Edible leaves',
    'Edible shoots',
    'Fish traps',
    'Furniture',
    'Handicraft',
    'Mats',
    'Miscellaneous uses',
    'Walking sticks',
    'Weaving'
];