export const rattanregion = [
    'Angola',
    'Australia',
    'Bali',
    'Bangladesh',
    'Benin',
    'Bhutan',
    'Borneo',
    'Burkina',
    'Cambodia',
    'Cameroon',
    'Central African Republic',
    'China',
    'Combodia',
    'Congo',
    'Cote d’Ivoire',
    'Equatorial Guinea',
    'Flores',
    'Gabon',
    'Gambia',
    'Ghana',
    'Guinea',
    'Guinea-Bissau',
    'Gulf of Guinea',
    'India',
    'Indonesia',
    'Ivory Coast',
    'Java',
    'Laos',
    'Liberia',
    'Malaysia',
    'Maluku Islands',
    'Myanmar',
    'Nepal',
    'New Guinea',
    'Niger',
    'Nigeria',
    'Peninsular Malaysia',
    'Peninsular Thailand',
    'Philippines',
    'Senegal',
    'Sierra Leone',
    'Singapore',
    'Sri Lanka',
    'Sudan',
    'Sulawesi',
    'Sumatra',
    'Sumbawa',
    'Thailand',
    'Togo',
    'Uganda',
    'Vietnam',
    'Wetar',
    'Zaire',
    'Zambia'
];