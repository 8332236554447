import React from 'react';
import './custom.css';
import { TopHeader } from './components/TopHeader';
import { Footer } from './components/Footer';
import Box, {
    Item
} from 'devextreme-react/box';
import 'whatwg-fetch';


export const Home = () => {
    return (
        <React.Fragment>
            <TopHeader />
            <Box  >
                <Item 
                    ratio={2}
                    baseSize={0}>
                    <Box direction="row" width="100%" height={350} >

                        <Item ratio={1} >
                            <div style={{ textAlign: "center" }} className="dx-fieldset" >
                                <p style={{ margin: "0 auto 0 auto", fontSize: "28px", fontWeight: "bold" }}>About INBAR’s Bamboo and Rattan Species Selection tool</p>
                            </div>
                            <div> <p style={{ textIndent: "0em", margin: "0px 30px 0 30px", fontSize: "14px" }}>The INBAR Bamboo and Rattan Species Selection tool is designed with aim to help users to select right species for right purpose and right habitat in an easy way. The tool contains sets of environmental parameters (such as soil, climatic conditions) and product development parameters, which will be used for searching the most suitable species for development. Selected species should be able to grow in the soil and climatic conditions of the site while be able to deliver suitable culms for development of desirable products and supply chains.
                            </p>
                                <p style={{ textIndent: "0em", margin: "20px 30px 0 30px", fontSize: "14px" }}>Users can enter input data of ecological conditions of targeted site and product expectation, then the tool will sort out species that matches site conditions and product expectation. It also provides detailed information (such as associated vegetation communities, knowledge of propagation and management) about the selected species.</p>
                                <p style={{ textIndent: "0em", margin: "20px 30px 0 30px", fontSize: "14px" }}>The data in INBAR’s Bamboo and Rattan Species Selection tool is regularly updated, based on existing scientific evidences.</p>

                            </div>                            
                        </Item>

                        
                    </Box>
     

                </Item>
            </Box>
            <Box height={400} style={{ margin: "20px 300px 0px 300px"}} >
                <Item ratio={1} >
                    <div style={{ textAlign: "center", margin:"0px 0px  0px  0px" }} >
                        <a href="/bamboo">
                            <img width="96px" src="images/bamboo_icon.png" alt="bamboo" />
                            <p style={{ fontSize: "16px" , fontWeight: "bold" }}>Search bamboo</p>
                        </a>
                    </div>
                </Item>
                <Item ratio={1}>
                    <div style={{ textAlign: "center", margin: "0px  0px 0px 0px"  }} >
                        <a href="/rattan">
                            <img width="96px" src="images/rattan_icon.jpg" alt="rattan" />
                            <p style={{ fontSize: "16px", fontWeight: "bold" }}>Search rattan</p>
                        </a>
                    </div>
                </Item>
                <Item ratio={1}>
                    <div style={{ textAlign: "center", margin: "0px  0px 0px 0px" }} >
                        <a href="/com.inbar.species.apk">
                            <img width="96px" src="images/app_url_qr.png" alt="Android app" />
                            <p style={{ fontSize: "16px", fontWeight: "bold" }}>Download app</p>
                        </a>
                    </div>
                </Item>
            </Box>

            <Footer />
        </React.Fragment>
    );
}
