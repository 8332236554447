import React, { Component } from 'react';

import Box, {
    Item
} from 'devextreme-react/box';

import DocumentTitle from 'react-document-title'

import './DetailItem.css';

export class RattanDetailItem extends React.Component {

    static displayName = RattanDetailItem.name;
    constructor(props) {
        super(props);
        this.state = {
            bamboo: [],
            title: '',
            height: 1850,
            loading: true
        };
    }

    componentDidMount() {
        this.populateBambooData();
        window.addEventListener('resize', this.handleHeight);

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleHeight);
    }

    handleHeight = () => {
        const screenHeight = document.documentElement.clientHeight;
        let height = `${screenHeight - 300}px`;
        this.setState({
            height,
        });
    }

    static renderBambooTable(data) {
        var VernacularNames = "";
        if (data.VernacularNames != "") {
            VernacularNames = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">Vernacular Names</div>
                <div className="dx-field-value-static">{data.VernacularNames}</div>
            </div>
        }

        var Description = "";
        if (data.Description != "") {
            Description = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">Description</div>
                <div className="dx-field-value-static">{data.Description}</div>
            </div>
        }

        var Geographical = "";
        if (data.Geographical != "") {
            Geographical = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">Natural distribution</div>
                <div className="dx-field-value-static" style={{ whiteSpace: "pre-wrap" }}>{data.Geographical.replaceAll(";  ", "\n").replaceAll("; ", "\n").replaceAll(";", "\n").replaceAll(",  ", "\n").replaceAll(", ", "\n").replaceAll(",", "\n")}</div>
            </div>
        }

        var AgroEcologica = "";
        if (data.AgroEcologica != "") {
            AgroEcologica = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">Agro-ecologica</div>
                <div className="dx-field-value-static">{data.AgroEcologica}</div>
            </div>
        }

        var Silviculture = "";
        if (data.Silviculture != "") {
            Silviculture = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">Silviculture</div>
                <div className="dx-field-value-static">{data.Silviculture}</div>
            </div>
        }

        var Diseases = "";
        if (data.Diseases != "") {
            Diseases = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">Diseases</div>
                <div className="dx-field-value-static">{data.Diseases}</div>
            </div>
        }

        var Properties = "";
        if (data.Properties != "") {
            Properties = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">Properties</div>
                <div className="dx-field-value-static">{data.Properties}</div>
            </div>
        }
        var Plantation = "";
        if (data.Plantation != "") {
            Plantation = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">Plantation</div>
                <div className="dx-field-value-static">{data.Plantation}</div>
            </div>
        }

        var Status = "";
        if (data.Status != "") {
            Status = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">Status</div>
                <div className="dx-field-value-static">{data.Status}</div>
            </div>
        }

        var uses = "";
        if (data.Uses != "") {
            uses = <div className="dx-field">
                    <div style={{ paddingLeft: '20px' }} className="dx-field-label">Uses</div>
                <div className="dx-field-value-static" style={{ whiteSpace: "pre-wrap" }}>{data.Uses.replaceAll(";  ", "\n").replaceAll("; ", "\n").replaceAll(";", "\n").replaceAll(",  ", "\n").replaceAll(", ", "\n").replaceAll(",", "\n")}</div>
                </div>
        }

        var AssociatedVegetation = "";
        if (data.AssociatedVegetation != "") {
            AssociatedVegetation = <div className="dx-field">
                <div style={{ paddingLeft: '20px' }} className="dx-field-label">Associated vegetation</div>
                <div className="dx-field-value-static" style={{ whiteSpace: "pre-wrap" }}>{data.AssociatedVegetation.replaceAll(";  ", "\n").replaceAll("; ", "\n").replaceAll(";", "\n").replaceAll(",  ", "\n").replaceAll(", ", "\n").replaceAll(",", "\n")}</div>
            </div>
        }
        return (

            <><DocumentTitle title={data.ScientificName + " - Inbar species selection"}></DocumentTitle>
                <div key={data.gid} className="dx-fieldset">
                    <div className="dx-fieldset-header">{data.ScientificName}</div>
                    {VernacularNames}
                    {Description}
                    {Geographical}
                    {AssociatedVegetation}
                    {AgroEcologica}
                    {Silviculture}
                    {Diseases}
                    {Properties}
                    {Plantation}
                    {Status}
                    {uses}
                </div></>


        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : RattanDetailItem.renderBambooTable(this.state.bamboo);

        return (
            <Box style={{ marginLeft: '50px' }}
                direction="row"
                width="80%"
                height={'auto'}
                align="start"
                crossAlign="start">
                <Item ratio={0} baseSize={1024}>
                    {contents}
                </Item>
            </Box>
        );
    }

    async populateBambooData() {
        var url = window.location.href;
        var arr = url.split('/')
        var guid = arr[arr.length - 1]
        const response = await fetch('api/RattanData/' + guid);
        const data = await response.json();
        this.setState({ bamboo: data, loading: false });
    }
}